import { TranslationFunction } from "i18n-config";
import get from "lodash/get";
import { useMemo } from "react";

export type TranslationProp<TranslationMap extends Record<string, any>> =
  | {
      t: TranslationFunction<TranslationMap>;
      translations?: undefined;
    }
  | {
      t?: undefined;
      translations: TranslationMap;
    };

export type UseTranslationProps<TranslationMap extends Record<string, any>> = {
  translation: TranslationProp<TranslationMap>;
};

export const useTranslation = <TranslationMap extends Record<string, any>>({
  translation,
}: UseTranslationProps<TranslationMap>) => {
  const t: TranslationFunction<TranslationMap> = useMemo(() => {
    if (translation.t) {
      return translation.t;
    }

    const fun: TranslationFunction<TranslationMap> = (key, data) => {
      let value = get(translation.translations, key) as string;

      if (data) {
        value = value.replace(/{([^{{}}]+)}/g, function (keyExpr, key) {
          return data[key] || "";
        });
      }
      return value;
    };
    return fun;
  }, [translation]);

  return { t };
};
